
import { Options, Vue } from 'vue-class-component'
import VInput from '@/components/package/input/Input.vue' // @ is an alias to /src
// import VMessage from '@/components/package/message/Message.vue'

import { copy } from '@/utils'

@Options({
  components: {
    VInput
  }
})
export default class Login extends Vue {
  phone: string = ''
  verifyCode: string = ''
  inviteCode: string = ''
  message: string = ''
  timing: number = 60
  interval?: number = 0
  openId?: string = ''

  async onSendVerifyCode (): Promise<void> {
    if (this.interval) {
      return
    }
    if (!this.phone) {
      this.message = '请输入手机号'
      return
    }
    if (!/^[1][3-9][0-9]{9}$/.test(this.phone)) {
      this.message = '请输入正确的手机号'
      return
    }
    const res = await this.$api.user.sendMessage(this.phone)
    if (res.code === 1000) {
      this.clearInterval()
      this.interval = window.setInterval(() => {
        if (this.timing <= 0) {
          this.clearInterval()
        }
        this.timing = this.timing - 1
      }, 1000)
    }
  }

  clearInterval (): void {
    if (this.interval) {
      window.clearInterval(this.interval)
      this.interval = 0
      this.timing = 60
    }
  }

  async onInviteCode (): Promise<void> {
    await this.$message.alert({
      title: '没有邀请码怎么办？',
      message: '请联系身边的好友索取邀请码。找不到朋友索取，可以直接填写小美的邀请码66982581哦',
      confirmText: '复制邀请码'
    })
    await copy('66982581')
    this.$toast('复制成功')
  }

  async onSubmit (): Promise<void> {
    if (!this.openId) {
      this.message = '参数错误'
      return
    }
    if (!this.phone) {
      this.message = '请输入手机号'
      return
    }
    if (!/^[1][3-9][0-9]{9}$/.test(this.phone)) {
      this.message = '请输入正确的手机号'
      return
    }
    if (!this.verifyCode) {
      this.message = '短信验证码'
      return
    }
    if (!this.inviteCode) {
      this.message = '请输入邀请码'
      return
    }
    this.message = ''
    const res = await this.$api.user.regist({
      phone: this.phone,
      smsCode: this.verifyCode,
      inviteCode: this.inviteCode,
      openId: this.openId
    })
    if (res.code === 1000) {
      this.$toast({
        message: '认领小美成功！返回服务号开始省钱之旅！',
        duration: 5000
      })
    } else {
      this.message = res.message
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('openId')) {
      this.openId = searchParams.get('openId') as string
    }
    if (searchParams.get('inviteCode')) {
      this.inviteCode = searchParams.get('inviteCode') as string
    }
  }

  beforeUnmount (): void {
    this.clearInterval()
  }
}
