<template>
  <div class="v-input">
    <div class="content">
      <input
        :value="modelValue"
        v-bind="$attrs"
        class="inner"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"/>
    </div>
    <button
      v-if="!!modelValue"
      class="clear"
      @click="onClearValue"/>
    <div
      v-if="$slots.append"
      class="append">
      <slot name="append"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: 'v-input',
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: ''
    }
  },
  setup (props, { emit }) {
    function onInput (e: Event): void {
      emit('update:modelValue', (e.target as HTMLInputElement).value)
      emit('input', e)
    }
    function onFocus (e: Event): void {
      emit('focus', e)
    }
    function onBlur (e: Event): void {
      emit('blur', e)
    }
    function onClearValue (): void {
      emit('update:modelValue', '')
    }
    return {
      onInput,
      onFocus,
      onBlur,
      onClearValue
    }
  }
})
</script>

<style lang="scss" scoped>
.v-input {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
  .content {
    flex: auto;
    .inner {
      display: block;
      width: 100%;
      height: 30px;
      color: #333;
      font-size: 16px;
      line-height: 30px;
      box-sizing: border-box;
      border: 0;
      outline: 0;
      padding: 0 10px;
      &::placeholder {
        color: #999;
      }
    }
  }
  .clear {
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 60px;
    box-sizing: content-box;
    border: 0;
    outline: 0;
    padding: 0 10px;
    background-color: transparent;
    background-image: url('~@/assets/images/icons/clear.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
  }
  .append {
    display: flex;
    flex-shrink: 0;
    width: 100px;
    height: 20px;
    border-left: 1px solid #e5e5e5;
  }
}
</style>
