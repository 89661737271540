<template>
  <div class="login">
    <div class="form">
      <div class="item">
        <v-input
          v-model="phone"
          type="number"
          maxlength="11"
          placeholder="请输入手机号"/>
      </div>
      <div class="item">
        <v-input
          v-model="verifyCode"
          type="number"
          placeholder="短信验证码">
          <template v-slot:append>
            <button
              :class="{
                'verify-code': true,
                'disabled': interval
              }"
              @click="onSendVerifyCode">{{ interval ? timing + 'S后重获' : '获取验证码' }}</button>
          </template>
        </v-input>
      </div>
      <div class="item">
        <v-input
          v-model="inviteCode"
          type="number"
          placeholder="请输入邀请码"/>
      </div>
    </div>
    <div class="prompt">
      <div class="message"><i v-if="message" class="icon"/>{{ message }}</div>
      <button
        class="button"
        @click="onInviteCode">没有邀请码？</button>
    </div>
    <div class="submit">
      <button
        :class="{
          'button': true,
          'disabled': !phone || !verifyCode || !inviteCode
        }"
        @click="onSubmit">立即开启省钱权益</button>
    </div>
    <!-- <v-message
      v-model:visible="visible"
      :message="'请填写小美得邀请码吧\nXVCFGG'"
      confirmButtonText="复制邀请码"
      @confirm="onCopyInviteCode"/> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import VInput from '@/components/package/input/Input.vue' // @ is an alias to /src
// import VMessage from '@/components/package/message/Message.vue'

import { copy } from '@/utils'

@Options({
  components: {
    VInput
  }
})
export default class Login extends Vue {
  phone: string = ''
  verifyCode: string = ''
  inviteCode: string = ''
  message: string = ''
  timing: number = 60
  interval?: number = 0
  openId?: string = ''

  async onSendVerifyCode (): Promise<void> {
    if (this.interval) {
      return
    }
    if (!this.phone) {
      this.message = '请输入手机号'
      return
    }
    if (!/^[1][3-9][0-9]{9}$/.test(this.phone)) {
      this.message = '请输入正确的手机号'
      return
    }
    const res = await this.$api.user.sendMessage(this.phone)
    if (res.code === 1000) {
      this.clearInterval()
      this.interval = window.setInterval(() => {
        if (this.timing <= 0) {
          this.clearInterval()
        }
        this.timing = this.timing - 1
      }, 1000)
    }
  }

  clearInterval (): void {
    if (this.interval) {
      window.clearInterval(this.interval)
      this.interval = 0
      this.timing = 60
    }
  }

  async onInviteCode (): Promise<void> {
    await this.$message.alert({
      title: '没有邀请码怎么办？',
      message: '请联系身边的好友索取邀请码。找不到朋友索取，可以直接填写小美的邀请码66982581哦',
      confirmText: '复制邀请码'
    })
    await copy('66982581')
    this.$toast('复制成功')
  }

  async onSubmit (): Promise<void> {
    if (!this.openId) {
      this.message = '参数错误'
      return
    }
    if (!this.phone) {
      this.message = '请输入手机号'
      return
    }
    if (!/^[1][3-9][0-9]{9}$/.test(this.phone)) {
      this.message = '请输入正确的手机号'
      return
    }
    if (!this.verifyCode) {
      this.message = '短信验证码'
      return
    }
    if (!this.inviteCode) {
      this.message = '请输入邀请码'
      return
    }
    this.message = ''
    const res = await this.$api.user.regist({
      phone: this.phone,
      smsCode: this.verifyCode,
      inviteCode: this.inviteCode,
      openId: this.openId
    })
    if (res.code === 1000) {
      this.$toast({
        message: '认领小美成功！返回服务号开始省钱之旅！',
        duration: 5000
      })
    } else {
      this.message = res.message
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('openId')) {
      this.openId = searchParams.get('openId') as string
    }
    if (searchParams.get('inviteCode')) {
      this.inviteCode = searchParams.get('inviteCode') as string
    }
  }

  beforeUnmount (): void {
    this.clearInterval()
  }
}
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  background-color: #fff;
  .form {
    padding: 0 40px;
    .item {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      .verify-code {
        display: block;
        width: 100%;
        height: 20px;
        font-size: 16px;
        color: #f68733;
        background-color: transparent;
        line-height: 20px;
        border: 0;
        outline: 0;
        padding: 0 10px;
        &:active {
          color: rgba(#f68733, 0.7);
        }
        &.disabled {
          color: #999;
        }
      }
    }
  }
  .prompt {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 40px;
    .message {
      font-size: 13px;
      color: #ed5627;
      line-height: 40px;
      .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('~@/assets/images/icons/prompt-red.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 12px;
        vertical-align: middle;
        margin: -2px 5px 0 0;
      }
    }
    .button {
      height: 40px;
      font-size: 13px;
      color: #f68733;
      background-color: transparent;
      line-height: 40px;
      border: 0;
      outline: 0;
      padding: 0;
      &:active {
        color: rgba(#f68733, 0.7);
      }
    }
  }
  .submit {
    margin: 20px 30px 0 30px;
    .button {
      display: block;
      width: 100%;
      height: 50px;
      color: #fff;
      font-size: 17px;
      background-color: #f68733;
      box-shadow: 0 2px 9px 0 rgba(#e6e6e6, 0.5);
      line-height: 50px;
      border-radius: 25px;
      border: 0;
      outline: 0;
      &:active {
        color: rgba(#fff, 0.7);
        background-color: rgba(#f68733, 0.7);
      }
      &.disabled {
        color: #fff;
        background-color: #ccc;
        &:active {
          color: rgba(#fff, 0.7);
          background-color: rgba(#ccc, 0.7);
        }
      }
    }
  }
}
</style>
