
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: 'v-input',
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: ''
    }
  },
  setup (props, { emit }) {
    function onInput (e: Event): void {
      emit('update:modelValue', (e.target as HTMLInputElement).value)
      emit('input', e)
    }
    function onFocus (e: Event): void {
      emit('focus', e)
    }
    function onBlur (e: Event): void {
      emit('blur', e)
    }
    function onClearValue (): void {
      emit('update:modelValue', '')
    }
    return {
      onInput,
      onFocus,
      onBlur,
      onClearValue
    }
  }
})
